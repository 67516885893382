// import Swiper from 'swiper';
const Swiper = require('swiper/js/swiper.js');
// import Swiper from 'swiper/bundle';


//サムネイル
const sliderThumbnail = new Swiper('.slider-thumbnail', {
	slidesPerView: "auto",
	freeMode: true,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
});

//スライダー
const slider = new Swiper('.slider', {
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	thumbs: {
		swiper: sliderThumbnail
	}
});
/*
*/


$(function () {
	$(document).on('click', '.navopen', function () {
		$('#header').toggleClass('is-active');
	})
	if ($(".akiyapage__search").length && $(window).width() <= 768) {

		$(document).on('click', '.filterlist .title', function () {
			$(this).next().slideToggle();
		})
	}
	if ($('.inpage__header').length) {
		$('body').css({
			"padding-top": $("#header").outerHeight()
		})
	}
})

$(window).on('resize', function () {
	if ($(window).width() > 768) {
		$(".filterlist .title").next().show();
	}
	if ($('.inpage__header').length) {
		$('body').css({
			"padding-top": $("#header").outerHeight()
		})
	}
})

$(window).on('scroll', function () {
	if ($('.toppage__side').length) {
		const offset = $('.toppage__vacanthouse').offset();
		if ($(window).scrollTop() > (offset.top / 2)) {
			$("#header").addClass('fixed');
		} else {
			$("#header").removeClass('fixed animate');
		}
		if ($(window).scrollTop() > offset.top) {
			$("#header").addClass('show animate');
			$(".toppage__side").addClass('show');
		} else {
			$("#header").removeClass('show');
			$(".toppage__side").removeClass('show');
		}
	}
})

$(window).on('load', function () {
	if ($('.akiyapage__single__map').length) {
		const target = document.getElementById('map');
		const address = $('.marker').attr('data-addr');
		const latlng = {
			lat: parseFloat($('.marker').attr('data-lat')),
			lng: parseFloat($('.marker').attr('data-long')),
		};
		const geocoder = new google.maps.Geocoder();
		if ($('.marker').attr('data-lat') !== '' && $('.marker').attr('data-lat')) {
			console.log(latlng);
			geocoder.geocode({ location: latlng }, function (results, status) {
				if (status === 'OK' && results[0]) {  //status を確認して処理開始
					console.log(status)
					new google.maps.Map(target, {
						center: latlng,
						zoom: 16
					});
				} else {
					alert('失敗しました。理由: ' + status);
				}
			});
		} else {
			geocoder.geocode({ address: address }, function (results, status) {
				if (status === 'OK' && results[0]) {  //status を確認して処理開始
					console.log(status)
					new google.maps.Map(target, {
						center: results[0].geometry.location,
						zoom: 16
					});
				} else {
					alert('失敗しました。理由: ' + status);
				}
			});
		}
	}
});


import ScrollHint from 'scroll-hint';
new ScrollHint('.js-scrollable', {
	i18n: {
		scrollable: 'スクロールできます'
	}
});
