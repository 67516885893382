const moment = require('moment');
import AOS from 'aos';
/**
	* 単純なサンプル
	* moment.jsを使って現在日付をconsole出力するだけ
	*/
$(function () {
	AOS.init({
		offset: -150,
		duration: 800,
		anchorPlacement: 'top-center'
	});


	if ($('.toppage__map').length) {
		const myLatLng = { lat: 37.465678, lng: 139.833114 };
		const map = new google.maps.Map(document.getElementById("map"), {
			zoom: 15,
			center: myLatLng,
		});
		new google.maps.Marker({
			position: myLatLng,
			map,
			title: "",
		});
	}
});

if ($('#instagram').length) {
	$.ajax({
		type: 'GET',
		url: '/_inc/instagram.php',
		dataType: 'json',
		success: function (json) {
			console.log(json);
			const datas = json.media.data;
			for (let i in datas) {
				let thumbnail = datas[i].thumbnail_url;
				let url = datas[i].media_url;
				if (typeof thumbnail !== "undefined") {
					console.log(thumbnail);
					url = datas[i].thumbnail_url;
				}
				let shortcode = datas[i].permalink;
				this.html = `<div class="item" style="background: url(${url}) center / cover no-repeat;"><a class="anchor" href="${shortcode}" target="_blank"></a></div>`;
				$("#instagram").append(this.html);
			}
		}
	});
}
